import React, { ChangeEvent, useEffect, useState } from "react";
// @ts-ignore
import {
  breadcrumb,
  DataFormClient,
  DataFormPayment,
  iconsSvg,
  LoadingAFY,
  MenuItem,
  position,
  select,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { themeCore } from "../../assets/themes/theme";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import { Steps } from "../../enum/steps";
import { getCustomerAction, isApapAuto, isCustomer } from "../../fnx/customer";
import { PlanModel } from "../../model/plan";
import { Account, PolicyRequest } from "../../model/policy";
import LocalStorageService from "../../services/localStorage";
import {
  policyCreate,
  policyCreateClearData,
} from "../../store/policy/actions";
import {
  ApapAccountTypes,
  dataFilter,
  paymentSummaryStorybook,
} from "./paymentSummaryDto";
import { PaymentSummaryView } from "./view";
import { Customer } from "../../enum/customer";

import { useTranslation } from "react-i18next";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";
import { STEP_PAYMENTMETHOD } from "../../const/catalog";
import { useGetCatalogAccountType } from "../../fnx/getCatalogAccountType";
import { GetPersonById } from "../../fnx/person/getPersonById";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import showToast from "../../services/toast";
import { quoteOne } from "../../store/generateQuote/actions";
import { personByIdClearData } from "../../store/person/actions";
import { catalogAccountTypeClearData } from "../../store/plan/actions";
import { formatToTwoDecimals } from "../../utils/getPrice";

const STEP = STEP_PAYMENTMETHOD;
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

const PaymentSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountList, setAccountList] = useState<Account[]>([]);
  const [personId, setPersonId] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState("");
  const [personTrigger, setPersonTrigger] = useState(false);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [subTitlePayment, setSubTitlePayment] = useState<string>("");
  const [listAccountTypes, setListAccountTypes] = useState<select[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [dataFormPayment, setDataFormPayment] = useState<DataFormPayment>({});
  const [projectId, setProjectId] = React.useState<string>("");
  const [cardSelected, setCardSelected] = useState<boolean>(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || ""
  );
  const [quote] = React.useState<string | undefined>(
    localStorageService.getStepStored().steps[Steps.customer].quoteId
  );

  const [paymentListOptions, setPaymentListOptions] = useState<
    { id: string; name: string; option: number; disabled: boolean }[]
  >([]);
  const [paymentListOption, setPaymentListOption] = useState({
    id: "",
    name: "",
    option: 0,
  });
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [dataForm, setDataForm] = useState<
    DataFormClient & { Insurance?: string } & { CoverType?: string } & {
      PaymentMode?: string;
    } & { Currency?: string } & { Prima?: string } & { QuoteId?: string }
  >({
    Name: "",
    LastNames: "",
    DocumentNumber: "",
    Email: "",
    Insurance: "",
    PlanTypeName: "",
    CampaignName: "",
    CoverType: "",
    PaymentMode: "",
    Currency: "",
    Prima: "",
    QuoteId: "",
  });

  const { responseCreatePolicy, errorCreatePolicy, loadingCreatePolicy } =
    useSelector((state: any) => ({
      responseCreatePolicy: state.Policy.responseCreatePolicy,
      errorCreatePolicy: state.Policy.errorCreatePolicy,
      loadingCreatePolicy: state.Policy.loadingCreatePolicy,
    }));

  const { responseCatalogAccountType, errorCatalogAccountType } =
    useGetCatalogAccountType(trigger, projectId);

  const { responsePersonById, errorPersonById, loadingPersonById } =
    GetPersonById(personTrigger, personId);

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote?.data,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    })
  );

  useEffect(() => {
    setTrigger(true);
    setSteps(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));
    dispatch(
      quoteOne({
        id: quote,
        fullBody: true,
      })
    );
  }, []);

  useEffect(() => {
    if (responsePersonById?.data) {
      setSubTitlePayment("");
      if (getCustomerAction().customerAuto.includes(company)) {
        console.log("Es Apap-auto y es cliente");
        if (!responsePersonById.data.additionalContentMap?.accounts?.length) {
          console.log("No tiene otros tipos de pago, solo es pago en caja");
          showToastSuccessMessage(
            t(`paymentMethod.accountsAlert`),
            typeAlert.info,
            setSeeToast
          );
          if (isCustomer(Customer.FULL)) {
            setSubTitlePayment("No hay cuentas disponibles para hacer el pago");
            setPaymentListOption(paymentSummaryStorybook.paymentOptionV2);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV4);
          } else {
            setSubTitlePayment("No. Recibo de pago");
            setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV3);
          }
        } else {
          const accountListNew =
            responsePersonById.data.additionalContentMap?.accounts?.map(
              (accounts: any, idx: number) => ({
                id: idx + 1,
                cardTitles: [
                  t(`paymentMethod.cardTitles.accountType`),
                  t(`paymentMethod.cardTitles.accountNumber`),
                  t(`paymentMethod.cardTitles.accountHolder`),
                ],
                cardValues: [accounts.type, accounts.number, accounts.category],
                iconSvg: iconsSvg.canceledSales,
              })
            );
          const filteredAccountList: Account[] = accountListNew.filter(
            (account: Account) => account.cardValues[0] === "CREDIT_CARD"
          );
          console.log("Tiene otros tipos de pago ");
          setAccountList(filteredAccountList);
          //Validar si es auto para mostrar las opciones de pago en
          //  if (getCustomerAction().customerAuto.includes(company)) {

          if (isCustomer(Customer.FULL)) {
            console.log("Es cliente FULL");
            setSubTitlePayment("");

            setPaymentListOption(paymentSummaryStorybook.paymentOptionV2);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV4);
          } else {
            setSubTitlePayment("No. Recibo de pago");
            setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
            setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV2);
          }
          //}
        }
      }
      dispatch(personByIdClearData());
    }
  }, [responsePersonById]);

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorCreatePolicy) {
      showErrorToast(errorCreatePolicy, setSeeToast, t);
      setNextDisabled(true);
    }
  }, [errorCreatePolicy]);

  useEffect(() => {
    if (errorCatalogAccountType) {
      showErrorToast(errorCatalogAccountType, setSeeToast, t);
    }
  }, [errorCatalogAccountType]);

  useEffect(() => {
    if (!getCustomerAction().customerAuto.includes(company)) {
      console.log(
        "No es apap-auto, se obtienen los tipos de cuenta desde el responseCatalogAccountType"
      );
      if (
        responseCatalogAccountType &&
        responseCatalogAccountType.data?.length > 0
      ) {
        const data: select[] = responseCatalogAccountType.data?.map(
          (account: PlanModel) => {
            return {
              id: account.catalogAccountTypeId,
              value: account.catalogAccountTypeId,
              name: account.catalogAccountType?.description,
            };
          }
        );
        setListAccountTypes(data);
        dispatch(catalogAccountTypeClearData());
        setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
        setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV2);
      } else if (
        responseCatalogAccountType &&
        responseCatalogAccountType.data?.length === 0
      ) {
        setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV3);
        setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
        /* if (getCustomerAction().customerAuto.includes(company)) {
        setSubTitlePayment("No. Recibo de pago");
        setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV2);
      }*/
      }
    }
  }, [responseCatalogAccountType]);

  useEffect(() => {
    if (responseOneQuote) {
      setDataForm({
        Name: `${responseOneQuote?.firstNameA} ${responseOneQuote?.firstNameB}`,
        LastNames: `${responseOneQuote?.lastNameA} ${responseOneQuote?.lastNameB}`,
        DocumentNumber: responseOneQuote?.numberId,
        Email: responseOneQuote?.email,
        Insurance: responseOneQuote?.productId?.description,
        PlanTypeName: responseOneQuote?.planId?.name,
        CampaignName: responseOneQuote.campaignId?.name,
        CoverType: responseOneQuote?.coverType?.description,
        PaymentMode: getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || ""
        )
          ? responseOneQuote?.catalogPaymentMode?.description
          : responseOneQuote?.catalogPaymentMode?.description,
        Currency: getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || ""
        )
          ? responseOneQuote?.lastPrice.currencySymbol
          : responseOneQuote?.planId.price.currencySymbol,
        Prima: getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || ""
        )
          ? responseOneQuote?.lastPrice.amount
            ? formatToTwoDecimals(responseOneQuote?.lastPrice.amount)
            : "0.00"
          : responseOneQuote?.planId.price.amount,
        QuoteId: responseOneQuote.id,
      });
      setPersonId(responseOneQuote.insured.personId);
      setPersonTrigger(true);
      setProjectId(responseOneQuote?.planId.projectId);
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseCreatePolicy && responseCreatePolicy.data) {
      localStorageService.setStepStored({
        id: responseCreatePolicy.data.id,
        name: "Pay form",
        step: Steps.pay,
        completed: true,
        quoteId: "",
        campaign: "",
        policyId: responseCreatePolicy.data.id,
        email: dataForm.Email,
      });
      setNextDisabled(false);
      dispatch(policyCreateClearData());
      //navigate("/checkout/payment-confirmation/");
      navigateNext(STEP, navigate);
      dispatch(policyCreateClearData());
    }
  }, [responseCreatePolicy]);

  const createPolicy = (data: any) => {
    let dataSubmit: PolicyRequest = {
      data: {
        effectiveFrom: new Date().toISOString(),
        creationDate: new Date().toISOString(),
        userId: lsLogin.getUserLoginStored().userId,
        quoteId: dataForm.QuoteId,
        //catalogStatus: "60c01b66-f07a-42f3-9ce4-bd973f515fed",
        //paymentCurrency: "4442d70e-4013-4db2-b7e4-8d2265b219f0",
        //accountHolderType: "e9b361e2-def7-4789-90b2-b2168c696b92",
        accountType: getCustomerAction().customerAuto.includes(company)
          ? ""
          : data.type,
        accountTypeDescription: getCustomerAction().customerAuto.includes(
          company
        )
          ? accountType
          : data.paymentName,
        accountName: getCustomerAction().customerAuto.includes(company)
          ? accountName
          : data.AccountName && data.AccountName.replace(/\s+/g, "")
          ? data.AccountName.replace(/\s+/g, "")
          : `${dataForm.Name} ${dataForm.LastNames}`,
        // accountName: getCustomerAction().customerAuto.includes(company)
        //   ? accountName
        //   : data.AccountName.replace(/\s+/g, "")
        //   ? data.AccountName
        //   : `${dataForm.Name} ${dataForm.LastNames}`,
        accountMonth: 12,
        accountYear: 2022,
        accountNumber: getCustomerAction().customerAuto.includes(company)
          ? accountNumber
          : data.AccountNumber,
      },
    };
    if (getCustomerAction().customerAuto.includes(company)) {
      console.log("---Incluye apap auto---");
      console.log(data);
      // data.VoucherNumber = parseInt(data.VoucherNumber, 10);

      if (data.paymentId === "4") {
        console.log(data.paymentName);
        dataSubmit.data = {
          ...dataSubmit.data,
          accountName: `${dataForm.Name} ${dataForm.LastNames}`,
          accountNumber: data.VoucherNumber,
          accountType: ApapAccountTypes.APAP_VOUCHER,
          additionalPaymentData: {
            voucherDate: new Date().toISOString(),
            voucherAmount: parseFloat(responseOneQuote?.lastPrice.amount),
          },
        };
      }
      if (data.paymentId === "5") {
        console.log(data.paymentName);
        if (!cardSelected) {
          showToastSuccessMessage(
            t(`paymentMethod.selectPaymentType`),
            typeAlert.info,
            setSeeToast
          );
          return;
        }
        dataSubmit.data = {
          ...dataSubmit.data,
          accountName: accountName,
          accountType: ApapAccountTypes.APAP_ACCOUNT,
          accountNumber: accountNumber,
          additionalPaymentData: {
            voucherDate: new Date().toISOString(),
            voucherAmount: parseFloat(responseOneQuote?.lastPrice.amount),
          },
        };
      }
    }

    dispatch(policyCreate({ ...dataSubmit }));
  };

  const handlePrevious = () => {
    navigateBack(STEP, navigate);
  };

  const handleChangePayment = (event: ChangeEvent<HTMLInputElement>) => {
    setCardSelected(false);
    const { value } = event.target;
    if (value === "1") {
      setDataFormPayment({
        AccountName: `${dataForm.Name} ${dataForm.LastNames}`,
      });
    }
    if (value === "4") {
      setSubTitlePayment("No. Recibo de pago");
    } else {
      setSubTitlePayment("");
    }
  };

  const clickCard = (item: any) => {
    setAccountType(item.cardValues[0]);
    setAccountNumber(item.cardValues[1]);
    setAccountName(item.cardValues[2]);

    setCardSelected(true);
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <LoadingAFY
        loading={loadingCreatePolicy || loadingOneQuote || loadingPersonById}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        bgColor={themeCore.colors.backgroundLoader}
        text={
          loadingCreatePolicy
            ? t(`paymentMethod.loading.loading1`)
            : t(`paymentMethod.loading.loading2`)
        }
      />
      <HeaderTitlePage
        breadCrumbs={breadCrumbs}
        steps={steps}
        hideStep={false}
        stepsTop={"-5px"}
      />

      <PaymentSummaryView
        accountName={`${dataForm.Name} ${dataForm.LastNames}`}
        nextLabel={paymentSummaryStorybook.nextLabel}
        title={paymentSummaryStorybook.title}
        options={listAccountTypes}
        paymentOption={paymentListOption}
        paymentOptions={paymentListOptions}
        hideSubTitlePayment={true}
        subTitlePayment={subTitlePayment}
        previousLabel={paymentSummaryStorybook.previousLabel}
        stepProgress={paymentSummaryStorybook.stepProgress}
        onBack={handlePrevious}
        filters={dataFilter(dataForm)}
        dataForm={dataForm}
        onNext={createPolicy}
        onChangePayment={handleChangePayment}
        accountList={accountList}
        onClickCard={clickCard}
        voucherMaxLength={isApapAuto(company) ? 12 : 20}
        voucherMinLength={isApapAuto(company) ? 12 : 1}
        voucherIsAlphaNumeric={isApapAuto(company) ? true : false}
        nextDisabled={nextDisabled}
      />
    </React.Fragment>
  );
};

export default PaymentSummary;
