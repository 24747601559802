import React, { useEffect } from "react";
import { ResponseError } from "../../resources/ResponseError";
import Logo from "../../assets/images/logoAffinity.svg";

import { LoginView } from "./view";
import {
  LoadingAFY,
  onSignInDTO,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { useAuth } from "../../authContext";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../services/localStorage";
import { GetMenuHeader, GetMenuSideBarHome } from "../../services/nav";
import showToast from "../../services/toast";
import { getCustomerAction } from "../../fnx/customer";
import { useDispatch, useSelector } from "react-redux";
import i18Next from "i18next";
import { useTranslation } from "react-i18next";
import {
  loginUser,
  loginUserClearData,
  userConfirmEmailClearData,
  userConfirmPasswordClearData,
  userPasswordResetClearData,
} from "../../store/auth/actions";
import { showErrorToast } from "../../fnx/showError";
import { byPassLogin } from "../../../src/resources/helper/login";

const Login = () => {
  const [isFalseSSO, setIsFalseSSO] = React.useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, setStatus } = useAuth();
  const storageKey = "afy-login";
  const localStorageService = new LocalStorageService(storageKey);
  const { t } = useTranslation();

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseLogin, errorLogin, loadingLogin } = useSelector(
    (state: any) => ({
      responseLogin: state.Login.responseLogin,
      errorLogin: state.Login.errorLogin,
      loadingLogin: state.Login.loadingLogin,
    })
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const clientId = params.get("clientId");

    if (token && clientId) {
      console.log("Token: ", token);
      console.log("ClientId: ", clientId);
      byPassLogin(token, clientId).then((response) => {
        console.log("Response: ", response);
        handleLogin(response, token, true);
      });
    }else{
      setIsFalseSSO(false);
    }
  }, []);

  const handleLogin = (response: any, token: any, isBypass = false) => {
    console.log("****isBypass****", isBypass);
    setIsFalseSSO(isBypass)

    try {
      const storageKeyMh = "afy-menuh";
      const storageKeyMs = "afy-menus";
      const lsMH = new LocalStorageService(storageKeyMh);
      const lsMS = new LocalStorageService(storageKeyMs);
      let userDetails: any = {
        token: token,
        userName: response.user.name,
        email: response.user.email,
        clientId: response.client.id,
        countryId: response.client.countryId,
        branchId: response.branch.id,
        userId: response.user.id,
        subdomain: response.client.subdomain,
        roles: response.user.roles,
      };

      if (!isBypass) {
        userDetails.idToken = {
          jwtToken: response.accessToken.jwtToken,
          payload: {
            name: response.idToken.payload.name,
            family_name: response.idToken.payload.family_name,
          },
        };
      } else {
        userDetails.idToken = {
          jwtToken: token,
          payload: {
            name: response.user.name,
            family_name: response.user.family_name,
          },
        };
      }
      localStorageService.setUserLoginStored(userDetails);
      console.log("responseLogin.client.subdomain ", response.client.subdomain);

      if (
        getCustomerAction().customerAuto.includes(response.client.subdomain)
      ) {
        localStorage.setItem("i18nextLng", "es");
        localStorage.setItem("i18nextDefaultNamespace", "apapauto");

        i18Next.loadNamespaces("apapauto", () => {
          i18Next.setDefaultNamespace("apapauto");
          i18Next.changeLanguage("es");
        });
      } else {
        localStorage.setItem("i18nextLng", "es");
        localStorage.setItem("i18nextDefaultNamespace", "default");

        i18Next.loadNamespaces("default", () => {
          i18Next.setDefaultNamespace("default");
          i18Next.changeLanguage("es");
        });
      }

      lsMH.setMenuHorizontalStored(GetMenuHeader());
      lsMS.setMenuSideBarStored(GetMenuSideBarHome());

      setStatus("authenticated");
    } catch (error) {
      console.log("Error on login: ", error);
    }
  };
  useEffect(() => {
    if (responseLogin) {
      handleLogin(responseLogin, status, false);
    }
  }, [responseLogin]);

  useEffect(() => {
    if (errorLogin) {
      const error = errorLogin as ResponseError;
      let message;

      showErrorToast(errorLogin?.response, setSeeToast, t);

      if (error.response?.errors !== undefined) {
        let key = `login.errors.${error.response.errors[0]?.message}`;
        message = t(key);
      } else {
        console.log("Error: ", error.response?.message);
        message = t("error");
      }
      if (error) {
        if (error.response?.errors[0].message === "USER_NOT_CONFIRMED") {
          dispatch(loginUserClearData());
          dispatch(userConfirmEmailClearData());
          navigate("/confirmEmail");
        }
      }
    }
  }, [errorLogin]);

  const onClickForgetPassword = () => {
    dispatch(userPasswordResetClearData());
    dispatch(userConfirmPasswordClearData());
    navigate("/passwordReset");
  };

  const lgSingIn = (form: onSignInDTO) => {
    const storageKeyMh = "afy-menuh";
    const storageKeyMs = "afy-menus";
    const lsMH = new LocalStorageService(storageKeyMh);
    const lsMS = new LocalStorageService(storageKeyMs);

    /*    lsMH.setMenuHorizontalStored([]);
    lsMS.setMenuSideBarStored(GetMenuSideBarHome());
    lsMS.setMenuSideBarStored(GetMenuSideBarAdministration());
       */

    dispatch(
      loginUser({
        userName: form.email,
        password: form.password,
      })
    );
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({
            show: false,
            message: "",
            type: typeAlert.success,
          });
        })}
      <LoadingAFY
        loading={loadingLogin}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={t("login.loadingLogin")}
        bgColor={themeCore.colors.backgroundLoader}
      />
       {!isFalseSSO ? (
        <LoginView
          onSingIn={lgSingIn}
          logo={Logo}
          title={t("login.title")}
          emailError={t("login.emailError")}
          emailRequired={t("login.emailRequired")}
          emailText={t("login.emailText")}
          emailHelperText={t("login.emailHelperText")}
          passwordHelperText={t("login.passwordHelperText")}
          passwordText={t("login.passwordText")}
          passwordError={t("login.passwordError")}
          passwordRequired={t("login.passwordRequired")}
          labelButtonLogin={t("login.labelButtonLogin")}
          labelButtonForgetPassword={t("login.labelButtonForgetPassword")}
          labelInputPassword={t("login.labelInputPassword")}
          userForm={t("login.userForm")}
          onClickForgetPassword={onClickForgetPassword}
        />
      ) : <LoadingAFY
      loading={!loadingLogin}
      type={typeLoaders.FolderAnime}
      color={themeCore.colors.third}
      textColor={themeCore.colors.third}
      text={t("login.loadingLogin")}
      bgColor={themeCore.colors.backgroundLoader}
    />}
    </React.Fragment>
  );
};
export default Login;
